import React, { useEffect, useState } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import CreateAccountBlock from '../components/Blocks/CreateAccountBlock'
import HeadlineAndBodyTextWithVideo from '../components/Blocks/HeadlineAndBodyTextWithVideo'
import GalleryBlock from '../components/Blocks/GalleryBlock'
import AccordionBlock from '../components/Blocks/AccordionBlock'
import RelatedListenerBlock from '../components/Blocks/related-block/RelatedListenerBlock'
import HeadlineAndBodyTextBlock from '../components/Blocks/HeadlineAndBodyTextBlock'
import SearchUseCaseBlock from '../components/Blocks/search-block/SearchUseCaseBlock'
import BreadcrumbSection from '../components/breadcrumb'
import { getSlug, replaceStr } from '../helper'
import { USECASE_SHORTCODE } from '../constants'
import PopupSchedule from '../components/popupSchedule/PopupSchedule'

const useCaseDetail = ({ data, location }) => {
    const { contentSections, mainElements } = data.wpCptUseCase.cfUseCase
    const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = data.wpCptUseCase.cfUseCase.useCaseSeoFields
    const { ogTitle, ogDescription, ogImage, ogType, twitterCard } = data.wpCptUseCase.cfUseCase.useCaseSocialOpenGraphFields
    const usecaseName = [mainElements.usecaseNamePlural, mainElements.usecaseNameSingular]
    const usecaseUrl = getSlug(data.wpCptUseCase.uri)
    const breadcrumbItems = [
        {
            name: 'Use Cases',
            url: usecaseUrl,
        },
        {
            name: mainElements.breadcrumbValue,
        }

    ]

    const canonical = process.env.GATSBY_SITE_URL + location.pathname

    const [showSchedule, setShowSchedule] = useState(false)

    useEffect(() => {
        if (showSchedule) {
            document.querySelector('html').setAttribute('style', 'overflow:hidden')
        } else {
            document.querySelector('html').setAttribute('style', 'overflow:initial')
        }
    }, [showSchedule])

    useEffect(() => {
        if (window.location.href.includes("view-demo=1")) {
            setShowSchedule(true);
        }
    }, []);

    return (
        <Layout>
            <SEO
                title={replaceStr(USECASE_SHORTCODE, usecaseName, titleTag)}
                description={replaceStr(USECASE_SHORTCODE, usecaseName, metaDescription)}
                canonical={canonicalTagOverride || canonical}
                robotsDirective={robotsDirective}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogType={ogType}
                twitterCard={twitterCard}
                classBody='usecase-detail'
                breadcrumbItems={breadcrumbItems}
            />
            <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
            <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
            <section className="intro-usecase">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <HeadlineAndBodyTextBlock headlines={[{ headlineValue: replaceStr(USECASE_SHORTCODE, usecaseName, mainElements.h1), headlineType: 'H1' }, { headlineValue: replaceStr(USECASE_SHORTCODE, usecaseName, mainElements.h2), headlineType: 'H2' }]} />
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-4">
                            <CreateAccountBlock createAccountType='Smaller Version' customClass='use-case' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="use-case-video">
                <div className="container">
                    <HeadlineAndBodyTextWithVideo title={replaceStr(USECASE_SHORTCODE, usecaseName, mainElements.h3)} content={replaceStr(USECASE_SHORTCODE, usecaseName, contentSections.mainContent)} videoEmbed={contentSections.videoEmbedCode} hideSection={mainElements.hideSections} />
                </div>
            </section>
            {
                (!mainElements.hideSections || !mainElements.hideSections.includes('Code Examples')) && (
                    <section>
                        <div className="container">
                            <AccordionBlock accordionType='Code Example' headline={replaceStr(USECASE_SHORTCODE, usecaseName, contentSections.codeexamplesH4)} codeExamples={contentSections.codeExamples} />
                        </div>
                    </section>
                )
            }
            {
                (!mainElements.hideSections || !mainElements.hideSections.includes('Screenshots')) && contentSections.screenshotImages && (
                    <section className="use-case-gallery">
                        <div className="container">
                            <GalleryBlock headline={replaceStr(USECASE_SHORTCODE, usecaseName, contentSections.screenshotsH4)} gallery={contentSections.screenshotImages} />
                        </div>
                    </section>
                )
            }
            <section className="use-case-create-account">
                <div className="container">
                    <CreateAccountBlock />
                </div>
            </section>
            {
                (contentSections.relatedListeners && (!mainElements.hideSections || !mainElements.hideSections.includes('Related Listeners'))) && <section className="use-case-related">
                    <div className="container">
                        <RelatedListenerBlock titleH3={replaceStr(USECASE_SHORTCODE, usecaseName, contentSections.relatedlistenersH4)} description={replaceStr(USECASE_SHORTCODE, usecaseName, contentSections.relatedlistenerDescription)} relatedListeners={contentSections.relatedListeners} />
                    </div>
                </section>
            }
            <section className="use-case-detail-search">
                <div className="container">
                    <SearchUseCaseBlock headline={contentSections.descriptionAboveSearch} subHeadline={contentSections.instructionsAboveSearch} col6={true} useCaseh1Current={mainElements.h1} />
                </div>
            </section>
            {
                (!mainElements.hideSections || !mainElements.hideSections.includes('Video Transcript')) && (
                    <section className="use-case-transcript">
                        <div className="container">
                            <HeadlineAndBodyTextBlock
                                headlines={[{ headlineValue: replaceStr(USECASE_SHORTCODE, usecaseName, contentSections.videotranscriptH4), headlineType: 'H4' }]}
                                body={[{ bodyCopy: replaceStr(USECASE_SHORTCODE, usecaseName, contentSections.videoTranscriptContent) }]}
                            />
                        </div>
                    </section>
                )
            }
        </Layout>
    )
}

export default useCaseDetail

export const pageQuery = graphql`
     query UseCaseTemplate($slug: String!) {
        wpCptUseCase(cfUseCase: {mainElements: {slug: {eq: $slug}}}) {
            ...WordpressUseCaseFields
        }
    }
`
