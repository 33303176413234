import React from 'react'
import VideoBlock from './VideoBlock'
import { addSlashInternalLink } from '../../helper'

const HeadlineAndBodyTextWithVideo = ({ title, videoEmbed, content, hideSection }) => {
    return (
        <div className="headline-bodytext-video-wrapper">
            {title && (
                <div className="title">
                    <h3>{title}</h3>
                </div>
            )}
            {(videoEmbed && (!hideSection || !hideSection.includes('Video'))) && <VideoBlock layout="Full Width" videoEmbedCode={videoEmbed} />}
            {
                (content && (!hideSection || !hideSection.includes('Description'))) && (
                    <div className="content" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(content) }}></div>
                )
            }
        </div>
    )
}

export default HeadlineAndBodyTextWithVideo
